import React from "react"
import "../assets/scss/screen.scss"
import Header from "./Header"
import Footer from "./Footer"
import NewsletterSubscription from "./NewsletterSubscription"
import { graphql, useStaticQuery } from "gatsby"
import SearchPopup from "./SearchPopup"
import SearchPopupContext from "../util/searchPopupContext"
import Audio from "../components/alafasy/Audio"
import { useI18next } from "gatsby-plugin-react-i18next"
import BottomBanner from "../templates/bottomBanner"
import RigthBanner from "../templates/rigthBanner"
const Layout = ({ children }) => {
  const [popupVisible, setPopupVisible] = React.useState(false)

  const { language } = useI18next();

  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          stickyNav
        }
      }
    }
  `)
  const { stickyNav } = site.siteMetadata
  return (
    <div className="site-wrap" data-nav={stickyNav ? "sticky" : ""} lang={language}>
      <SearchPopupContext.Provider value={{ popupVisible, setPopupVisible }}>
        <Header />
            {children}
            <NewsletterSubscription />
            <BottomBanner />
            {/* <Audio /> */}
          
        <Footer />
        <SearchPopup />
      </SearchPopupContext.Provider>
    </div>
  )
}

export default Layout
graphql`
query ($language: String!) {
  locales: allLocale(
    filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
  ) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}
`;